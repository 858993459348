import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useAuth } from '../features/Auth/contexts/AuthContext'
import { BrandData } from '../types'
import { useRouter } from 'next/router'
import { useTeamContext } from './TeamContext'
import { getAllBrands, getBrand, getTalentBrands, getUserBrands, saveBrandFirestore } from '../api/firestore/brands'
import { getBrandProps } from '../api/firestore/props'
import { getBrandLocations } from '../api/firestore/locations'
import { omit } from 'lodash'
import { BrandTags, getBrandTags } from '../api/firestore/tags'
import { getTeam } from '../api/firestore/teams'

interface BrandContextData {
    brands: BrandData[]
    pendingBrands: BrandData[]
    handleUpdateBrands: (brands: BrandData[]) => void
    brand: BrandData | null
    saveBrand: (brand: BrandData) => Promise<void>
    handleSetBrand: (brand: BrandData) => void
    reloadBrand: (id: string, isCurrent?: boolean) => Promise<void>
    reloadBrands: () => Promise<void>
    brandLocations: string[]
    setBrandLocations: (locations: string[]) => void
    brandProps: string[]
    setBrandProps: (props: string[]) => void
    ownerTeamId: string
    brandOwnerId: string
    isViscapBrand: boolean
    brandTags: BrandTags
    setBrandTags: (brandTags: BrandTags) => void
}

export const BrandContext = createContext<BrandContextData>(null!)

export const useBrandContext = () => useContext(BrandContext)

const BrandContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const router = useRouter()
    const { userDB } = useAuth()
    const { brandId } = router.query
    const { currentTeam, isTalentView, isViscapTeam } = useTeamContext()
    const [brands, setBrands] = useState<BrandData[]>([])
    const [pendingBrands, setPendingBrands] = useState<BrandData[]>([])
    const [brand, setBrand] = useState<BrandData | null>(null)
    const [brandLocations, setBrandLocations] = useState([])
    const [brandProps, setBrandProps] = useState([])
    const [ownerTeamId, setOwnerTeamId] = useState('')
    const [isViscapBrand, setIsViscapBrand] = useState(false)
    const [brandTags, setBrandTags] = useState<BrandTags | null>(null)
    const [brandOwnerId, setBrandOwnerId] = useState('')

    const handleSetBrandProps = (props) => setBrandProps(props)
    const handleSetBrandLocations = (locations) => setBrandLocations(locations)
    const handleUpdateBrands = (brandsData) => setBrands(brandsData)
    const handleSetBrandTags = (tags) => setBrandTags(tags)

    const currentStorage: Storage | null = useMemo(() => {
        try {
            if (!sessionStorage || !localStorage) {
                return null
            }

            return sessionStorage.getItem('brand') ? sessionStorage : localStorage
        } catch (e) {
            return null
        }
    }, [])

    const handleSetBrand = useCallback(
        (brandData, storage?: Storage) => {
            if (!userDB || !currentStorage) return

            setBrand(brandData)
            ;(storage || currentStorage).setItem('brand', JSON.stringify({ ...brandData, uid: userDB.uid }))
        },
        [currentStorage, userDB]
    )

    const saveBrand = async (brand: BrandData) => {
        await saveBrandFirestore(brand)
        setBrands((prev) => prev.map((b) => (b.id === brand.id ? brand : b)))
    }

    const changeBrand = useCallback(
        (id: string, storage?: Storage) => {
            if (!brands.length) return

            const targetBrand = brands.find((b) => b.id === id)

            if (!targetBrand) return

            setBrand(targetBrand)

            handleSetBrand(targetBrand, storage)
        },
        [brands, handleSetBrand]
    )

    const reloadBrand = useCallback(
        async (id: string, isCurrent?: boolean) => {
            if (!userDB || !currentStorage) return

            const updatedBrand = await getBrand(id)

            if (isCurrent) {
                setBrand(updatedBrand)
                currentStorage.setItem('brand', JSON.stringify({ ...updatedBrand, uid: userDB.uid }))
            }

            const brandIndex = brands.findIndex((el) => el.id === id)

            if (brandIndex !== -1) {
                const updatedBrands = [...brands]

                updatedBrands.splice(brandIndex, 1, updatedBrand)

                setBrands(updatedBrands)
            }
        },
        [brands, currentStorage, userDB]
    )

    const reloadBrands = useCallback(async () => {
        if (currentTeam?.brands?.length > 0) {
            const fetchBrands = async () => {
                const res = await getAllBrands()
                setBrands(res)
            }

            const fetchUserBrands = async () => {
                const res = await getUserBrands(currentTeam.brands.map((b) => b.id))
                setBrands(res)
            }

            if (isViscapTeam) {
                await fetchBrands()
            } else {
                await fetchUserBrands()
            }
        }
    }, [currentTeam, isViscapTeam])

    useEffect(() => {
        const fetchBrands = async () => {
            let brands

            if (isTalentView) {
                brands = await getTalentBrands(userDB.talentProfileId)
            } else if (isViscapTeam) {
                brands = await getAllBrands()
            } else {
                brands = await getUserBrands(currentTeam.brands.map((br) => br.id))
            }

            const pendingBrandIds = currentTeam.brands.filter((br) => br.isPending).map((br) => br.id)

            setBrands(brands.filter((br) => !pendingBrandIds.includes(br.id)))
            setPendingBrands(brands.filter((br) => pendingBrandIds.includes(br.id)))
        }

        if (currentTeam) {
            fetchBrands()
        }
    }, [currentTeam, isTalentView, userDB, isViscapTeam])

    useEffect(() => {
        const fetchProps = async () => {
            const res = await getBrandProps(brand.id)
            setBrandProps(res || [])
        }

        const fetchLocations = async () => {
            const res = await getBrandLocations(brand.id)
            setBrandLocations(res || [])
        }

        const fetchTags = async () => {
            const res = await getBrandTags(brand.id)
            setBrandTags({
                clips: res?.clips || [],
                deliverables: res?.deliverables || [],
                elements: res?.elements || [],
                shootingSessions: res?.shootingSessions || [],
            })
        }

        if (brand?.id) {
            fetchProps()
            fetchLocations()
            fetchTags()
        }
    }, [brand?.id])

    useEffect(() => {
        if (
            !currentStorage ||
            !brands.length ||
            !userDB ||
            !currentTeam ||
            (brand && brands.find((b) => b.id === brand.id))
        ) {
            return
        }

        if (router.query.switchBrand) {
            changeBrand(router.query.switchBrand as string, sessionStorage)

            router.push({
                pathname: router.pathname,
                query: omit(router.query, 'switchBrand'),
            })

            return
        }

        if (brandId) {
            const targetBrand = brands.find((b) => b.id === brandId)

            setBrand(targetBrand)
            currentStorage.setItem('brand', JSON.stringify({ ...targetBrand, uid: userDB.uid }))

            return
        }

        const hasAccess = (brandData) => {
            if (isViscapTeam && userDB.role !== 'talent') return true
            return brandData.teams.some((t) => t.id === currentTeam.id)
        }

        if (currentStorage.getItem('brand')) {
            const localBrand = JSON.parse(currentStorage.getItem('brand'))

            const targetBrand = brands.find((b) => b.id === localBrand.id)

            if (!targetBrand || localBrand?.uid !== userDB?.uid || !hasAccess(targetBrand)) {
                setBrand(brands[0])
                currentStorage.setItem('brand', JSON.stringify({ ...brands[0], uid: userDB.uid }))
            } else {
                setBrand(targetBrand)
            }
        } else {
            setBrand(brands[0])
            currentStorage.setItem('brand', JSON.stringify({ ...brands[0], uid: userDB.uid }))
        }
    }, [brand, brandId, brands, userDB, currentTeam, currentStorage, router.query, router, changeBrand, isViscapTeam])

    useEffect(() => {
        const switchBrand = async () => {
            changeBrand(router.query.switchBrand as string, sessionStorage)

            await router.push({
                pathname: router.pathname,
                query: omit(router.query, 'switchBrand'),
            })
        }

        if (!router.query.switchBrand || !brand?.id || router.query.switchBrand === brand.id) {
            return
        }

        switchBrand()
    }, [brand, changeBrand, reloadBrand, router])

    useEffect(() => {
        const getOwnerTeamInfo = async () => {
            const teamEntry = brand.teams?.find((t) => t.teamRole === 'owners')
            setOwnerTeamId(teamEntry?.id || '')

            if (teamEntry?.id) {
                const team = await getTeam(teamEntry.id)
                setIsViscapBrand(team.email.includes('@viscapmedia.com'))
                setBrandOwnerId(team.users.find((u) => u.teamRole === 'owner').id)
            }
        }

        if (brand) {
            getOwnerTeamInfo()
        }
    }, [brand])

    const value = {
        brands,
        pendingBrands,
        handleUpdateBrands,
        brand,
        saveBrand,
        handleSetBrand,
        reloadBrand,
        reloadBrands,
        brandLocations,
        brandProps,
        setBrandLocations: handleSetBrandLocations,
        setBrandProps: handleSetBrandProps,
        ownerTeamId,
        brandOwnerId,
        isViscapBrand,
        brandTags,
        setBrandTags: handleSetBrandTags,
    }
    return <BrandContext.Provider value={value}>{children}</BrandContext.Provider>
}

export default BrandContextProvider
